import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import "./Games.css"; // Update the CSS file nameimport 'animate.css';
import "animate.css";
import {
  AllInONe,
  CashMachine,
  CasinoRoyal,
  DragonCrown,
  GameRoom,
  GameVault,
  Juwa,
  LuckyStars,
  Mafia,
  MawalRoom,
  MilkyWay,
  Orion,
  UltraPanda,
  VegaSweep,
  VegasLuck,
  WinStar,
} from "../../../Assets";

function AgentCards({ games }) {
  const handleOpenLinkInNewTab = (link) => {
    // Open the link in a new tab
    window.open(link, "_blank");
  };

  return (
    <>
      {/* <div className="gameslist-animate-container animate__animated animate__fadeIn"> */}

      <div className="gameslist-container animate__animated animate__fadeIn">
        {games
          .filter((x) => x.agent_link)
          .map((item, index) => (
            <div className="black-container">
              <h3>{item.games_name}</h3>

              <img
                src={`https://api-dog.dsmeglobal.com//${item.thumbnail}`}
                alt={item.games_name}
                className="casinoImage"
              />
              <button
                className="yellow-button"
                onClick={() => handleOpenLinkInNewTab(item.agent_link)}>
                <FontAwesomeIcon icon={faLink} className="icon" />
                Link
              </button>
            </div>
          ))}

        {/* <div className="black-container">
          <h3>LUCKY STARS</h3>
          <img src={LuckyStars} alt="Casino" className="casinoImage" />
          <button
            className="yellow-button"
            onClick={() => handleOpenLinkInNewTab("http://agent.luckystars.games")}
          >
            <FontAwesomeIcon icon={faLink} className="icon" />
            Link
          </button>
        </div>
        <div className="black-container">
          <h3>MR.ALLINONE ONLINE</h3>
          <img src={AllInONe} alt="Casino" className="casinoImage" />
          <button
            className="yellow-button"
            onClick={() =>
              handleOpenLinkInNewTab("http://agentserver.mrallinone777.com:8003/admin/login")
            }
          >
            <FontAwesomeIcon icon={faLink} className="icon" />
            Link
          </button>
        </div>
        <div className="black-container">
          <h3>CASH MACHINE</h3>
          <img src={CashMachine} alt="Casino" className="casinoImage" />
          <button
            className="yellow-button"
            onClick={() =>
              handleOpenLinkInNewTab("http://agentserver.cashmachine777.com:8003/admin/login")
            }
          >
            <FontAwesomeIcon icon={faLink} className="icon" />
            Link
          </button>
        </div>
        <div className="black-container">
          <h3>MAWAL GAMEROOM</h3>
          <img src={MawalRoom} alt="Casino" className="casinoImage" />
          <button
            className="yellow-button"
            onClick={() =>
              handleOpenLinkInNewTab("http://agentserver.mawalgameroom.com:8003/admin/login")
            }
          >
            <FontAwesomeIcon icon={faLink} className="icon" />
            Link
          </button>
        </div>
        <div className="black-container">
          <h3>GAMEROOM ONLINE</h3>
          <img src={GameRoom} alt="Casino" className="casinoImage" />
          <button
            className="yellow-button"
            onClick={() => handleOpenLinkInNewTab("https://productnew.gameroom777.com/v1015/")}
          >
            <FontAwesomeIcon icon={faLink} className="icon" />
            Link
          </button>
        </div>
        <div className="black-container">
          <h3>MAFIA</h3>
          <img src={Mafia} alt="Casino" className="casinoImage" />
          <button
            className="yellow-button"
            onClick={() =>
              handleOpenLinkInNewTab("http://agentserver.mafia77777.com:8003/admin/login")
            }
          >
            <FontAwesomeIcon icon={faLink} className="icon" />
            Link
          </button>
        </div>
        <div className="black-container">
          <h3>VEGAS LUCK</h3>
          <img src={VegasLuck} alt="Casino" className="casinoImage" />
          <button
            className="yellow-button"
            onClick={() => handleOpenLinkInNewTab("http://agent.vegasluck777.com:8002/")}
          >
            <FontAwesomeIcon icon={faLink} className="icon" />
            Link
          </button>
        </div>
        <div className="black-container">
          <h3>WIN STAR</h3>
          <img src={WinStar} alt="Casino" className="casinoImage" />
          <button
            className="yellow-button"
            onClick={() => handleOpenLinkInNewTab("http://agent.winstar99999.com:8003/")}
          >
            <FontAwesomeIcon icon={faLink} className="icon" />
            Link
          </button>
        </div>
        <div className="black-container">
          <h3>Orion Starss</h3>
          <img src={Orion} alt="joker" className="casinoImage" />
          <button
            className="yellow-button"
            onClick={() =>
              handleOpenLinkInNewTab("https://orionstars.vip:8781/default.aspx?638357730896684626")
            }
          >
            <FontAwesomeIcon icon={faLink} className="icon" />
            Link
          </button>
        </div>

        <div className="black-container">
          <h3>Juwa City</h3>
          <img src={Juwa} alt="Casino" className="casinoImage" />
          <button
            className="yellow-button"
            onClick={() => handleOpenLinkInNewTab("https://ht.juwa777.com/")}
          >
            {" "}
            <FontAwesomeIcon icon={faLink} className="icon" />
            Link
          </button>
        </div>

        <div className="black-container">
          <h3>Game Vault</h3>
          <img src={GameVault} alt="Casino" className="casinoImage" />
          <button
            className="yellow-button"
            onClick={() => handleOpenLinkInNewTab("https://agent.gamevault999.com/")}
          >
            {" "}
            <FontAwesomeIcon icon={faLink} className="icon" />
            Link
          </button>
        </div>
      </div> */}

        {/* --------second ro started here--------- */}

        {/* <div className="gameslist-container animate__animated animate__fadeIn">
        <div className="black-container">
          <h3>Casino Royale</h3>

          <img src={CasinoRoyal} alt="Casino" className="casinoImage" />
          <button
            className="yellow-button"
            onClick={() => handleOpenLinkInNewTab("https://agent.lasvegassweeps.com/adminList")}
          >
            {" "}
            <FontAwesomeIcon icon={faLink} className="icon" />
            Link
          </button>
        </div>
        <div className="black-container">
          <h3>Vegas Sweeps</h3>
          <img src={VegaSweep} alt="joker" className="casinoImage" />
          <button
            className="yellow-button"
            onClick={() =>
              handleOpenLinkInNewTab("https://orionstars.vip:8781/default.aspx?638357730896684626")
            }
          >
            {" "}
            <FontAwesomeIcon icon={faLink} className="icon" />
            Link
          </button>
        </div>

        <div className="black-container">
          <h3>Milky Ways</h3>
          <img src={MilkyWay} alt="Casino" className="casinoImage" />
          <button
            className="yellow-button"
            onClick={() =>
              handleOpenLinkInNewTab("https://milkywayapp.xyz:8781/default.aspx?638357734275031749")
            }
          >
            {" "}
            <FontAwesomeIcon icon={faLink} className="icon" />
            Link
          </button>
        </div>

        <div className="black-container">
          <h3>Ultra Panda</h3>

          <img src={UltraPanda} alt="Casino" className="casinoImage" />
          <button
            className="yellow-button"
            onClick={() =>
              handleOpenLinkInNewTab("https://ht.ultrapanda.mobi/#/manage-user/account")
            }
          >
            {" "}
            <FontAwesomeIcon icon={faLink} className="icon" />
            Link
          </button>
        </div>
      </div> */}
      </div>
    </>
  );
}

export default AgentCards;
