import React, { createContext, useState, useEffect } from "react";
import Web3 from "web3";

import {
  USDTabi,
  usdtAddress,
  chainIdTether,
  recipientAddress,
  BTCAbi,
  BTCAddress,
  chainIdBTC,
} from "../Constants/constants";

export const TransferContext = createContext();

let web3 = new Web3(window.ethereum);
const infuraEndpoint =
  "https://mainnet.infura.io/v3/6e2754c1907e44d0aea9fe116ebbe15d";
export default function TransferContextProvider({ children }) {
  let [walletAddress, setWalletAddress] = useState(null);
  let [successBit, setSuccessBit] = useState(false);
  let [selectedGame, setSelectedGame] = useState("");
  let [price, setPrice] = useState();
  let [hash, setHash] = useState();
  useEffect(() => {
    // Load wallet address from sessionStorage when the component mounts
    const storedWalletAddress = sessionStorage.getItem("walletAddress");
    if (storedWalletAddress) {
      setWalletAddress(storedWalletAddress);
    }
  }, []);

  async function connectWallet(
    chainID,
    rpcurl,
    chainName,
    blockExplorerUrl,
    nativeCurrency,
    nativeSymbol
  ) {
    if (
      typeof window !== "undefined" &&
      typeof window.ethereum !== "undefined"
    ) {
      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });

        const currentChainId = window.ethereum.chainId;

        if (currentChainId !== Web3.utils.toHex(chainID)) {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: Web3.utils.toHex(chainID),
                chainName: chainName,
                rpcUrls: [rpcurl],
                nativeCurrency: {
                  name: nativeCurrency,
                  symbol: nativeSymbol,
                  decimals: 18,
                },
                blockExplorerUrls: [blockExplorerUrl],
              },
            ],
          });
        }

        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: Web3.utils.toHex(chainID) }],
        });

        setWalletAddress(accounts[0]);

        // Save wallet address to sessionStorage
        sessionStorage.setItem("walletAddress", accounts[0]);
      } catch (err) {
        console.error(err.message);
      }
    } else {
      // console.log("Please install MetaMask");
    }
  }

  async function switchChain(
    chainID,
    rpcurl,
    chainName,
    blockExplorerUrl,
    nativeCurrency,
    nativeSymbol
  ) {
    if (
      typeof window !== "undefined" &&
      typeof window.ethereum !== "undefined"
    ) {
      try {
        const currentChainId = window.ethereum.chainId;

        if (currentChainId !== Web3.utils.toHex(chainID)) {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: Web3.utils.toHex(chainID),
                chainName: chainName,
                rpcUrls: [rpcurl],
                nativeCurrency: {
                  name: nativeCurrency,
                  symbol: nativeSymbol,
                  decimals: 18,
                },
                blockExplorerUrls: [blockExplorerUrl],
              },
            ],
          });

          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: Web3.utils.toHex(chainID) }],
          });
        }
      } catch (err) {
        console.error(err.message);
      }
    } else {
      // Handle wallet not available
      console.log("Please install MetaMask or connect a wallet");
    }
  }

  async function TransferUSDT(
    payableAmount,
    chainID,
    rpcurl,
    chainName,
    blockExplorerUrl,
    nativeCurrency,
    currencySymbol
  ) {
    await switchChain(
      chainID,
      rpcurl,
      chainName,
      blockExplorerUrl,
      nativeCurrency,
      currencySymbol
    );

    try {
      const USDTContract = await getTokenContract(USDTabi, usdtAddress);
      const decimals = await getDecimals(USDTabi, usdtAddress);
      // console.log("decimals", decimals);
      payableAmount = (payableAmount * 10 ** decimals).toLocaleString(
        "fullwide",
        {
          useGrouping: false,
        }
      );
      payableAmount = parseInt(payableAmount);

      const transferUSDT = await USDTContract.methods
        .transfer(recipientAddress, payableAmount)
        .send({
          from: walletAddress, // Specify the sender's address
          gas: 200000, // Adjust the gas limit as needed
        });

      const transactionHash = transferUSDT.transactionHash;
      setHash((hash = transferUSDT.transactionHash));
      // console.log("Transaction successful. Transaction Hash:", transactionHash);
      setSuccessBit((successBit = true));
      return transactionHash;
    } catch (error) {
      setSuccessBit((successBit = false));

      console.error("Transaction error:", error.message);
    }
  }
  async function TransferBTC(
    payableAmount,
    chainID,
    rpcurl,
    chainName,
    blockExplorerUrl,
    nativeCurrency,
    currencySymbol
  ) {
    // Connect wallet
    await switchChain(
      chainID,
      rpcurl,
      chainName,
      blockExplorerUrl,
      nativeCurrency,
      currencySymbol
    );

    try {
      const BTCContract = await getTokenContract(BTCAbi, BTCAddress);

      // Fetch decimals
      const decimals = 18;
      // Convert payableAmount to integer with decimals handling
      payableAmount = payableAmount * 10 ** decimals;
      payableAmount = parseInt(payableAmount);

      // Send BTC transfer transaction
      const transferBTC = await BTCContract.methods
        .transfer(recipientAddress, payableAmount)
        .send({
          from: walletAddress,
          gas: 200000, // Adjust gas limit as needed
        });

      // Retrieve transaction hash
      const transactionHash = transferBTC.transactionHash;
      setHash((hash = transferBTC.transactionHash));
      // console.log("Transaction successful. Transaction Hash:", transactionHash);
      setSuccessBit((successBit = true));
      return transactionHash;
    } catch (error) {
      setSuccessBit((successBit = false));

      console.error("Transaction error:", error.message);
    }
  }
  async function getTokenContract(tokenAbi, tokenAddress) {
    try {
      return new web3.eth.Contract(tokenAbi, tokenAddress);
    } catch (err) {
      console.log("error", err);
    }
  }
  async function getDecimals(tokenABI, tokenAddress) {
    try {
      const tokenContract = await getTokenContract(tokenABI, usdtAddress);

      let decimals = await tokenContract.methods.decimals().call();
      decimals = parseInt(decimals);
      // console.log("parsed decimals", decimals);
      // Log the value to check if an "n" is added
      // console.log("Decimals:", decimals);

      return decimals;
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <TransferContext.Provider
      value={{
        connectWallet,
        walletAddress,
        TransferUSDT,
        TransferBTC,
        successBit,
        selectedGame,
        setSelectedGame,
        price,
        setPrice,
        switchChain,
      }}>
      {children}
    </TransferContext.Provider>
  );
}
