import axios from "axios";
import { BASE_URL } from "../Constants/constants";

export async function GetAllConversion() {
  let route = BASE_URL.concat("dog/api/conversion/get-all");
  return axios.get(route, {
    ["axios-retry"]: {
      retries: 0,
    },
  });
}

export async function PostConversion(data) {
  let route = BASE_URL.concat("dog/api/conversion/post");
  return axios.post(route, data, {
    ["axios-retry"]: {
      retries: 0,
    },
  });
}

export async function getGamesCall(page, limit) {
  let params = {};
  if (page) params.page = page;
  if (limit) params.limit = limit;
  let route = "https://api-dog.dsmeglobal.com//dog/api/games/get-all";
  return axios.get(route, {
    params,
    ["axios-retry"]: {
      retries: 0,
    },
  });
}

export async function getAllVideo(page, limit) {
  let params = {};
  if (page) params.page = page;
  if (limit) params.limit = limit;
  let route = "https://api-dog.dsmeglobal.com//dog/api/video/get-all";
  return axios.get(route, {
    params,
    ["axios-retry"]: {
      retries: 0,
    },
  });
}
