import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import Slider from "react-slick";
import { SlArrowLeftCircle, SlArrowRightCircle } from "react-icons/sl";
import axios from "axios";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Video.css";

export default function HomeVideo() {
  const [videoUrls, setVideoUrls] = useState([]);
  const baseURL = "https://api-dog.dsmeglobal.com/";

  useEffect(() => {
    fetchVideoUrls();
  }, []);

  const fetchVideoUrls = async () => {
    try {
      const response = await axios.get("https://api-dog.dsmeglobal.com/dog/api/video/get-all");
      console.log("API Response:", response.data);
      if (response.data.success) {
        const urls = response.data.result.map(video => baseURL + video.video.replace(/\\/g, '/'));
        setVideoUrls(urls);
      } else {
        console.error("Failed to fetch videos:", response.data.error_code);
      }
    } catch (error) {
      console.error("Error fetching videos:", error);
    }
  };

  const settings = {
    dots: true,
    infinite: videoUrls.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <div className="video-slider-container">
      <Slider {...settings}>
        {videoUrls.map((video, index) => (
          <div key={index} className="video-slide">
            <ReactPlayer
              url={video}
              controls={false}
              playing={true}
              loop={true}
              muted={true}
              width="100%"
              height="100%"
              className="home_video_player"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}

// Custom arrow component for previous view
const CustomPrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-prev-arrow" onClick={onClick}>
      <SlArrowLeftCircle size={40} />
    </div>
  );
};

// Custom arrow component for next view
const CustomNextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-next-arrow" onClick={onClick}>
      <SlArrowRightCircle size={40} />
    </div>
  );
};
