import React, { useEffect, useState } from "react";
import "./Games.css"; // Update the CSS file name
import PlayersCards from "./PlayersCards";
import AgentCards from "./AgentCards";
import { getGamesCall } from "../../../Api/Repo";

const Games = () => {
  const [activeButton, setActiveButton] = useState("play");
  const [games, setGames] = useState([]);

  const [isAgentActive, setIsAgentActive] = useState(false);

  useEffect(() => {
    getGames();
  }, []);

  function getGames() {
    getGamesCall(1, 1000)
      .then(({ data }) => {
        console.log("data", data);
        if (data.error_code == 0) setGames(data.result);
        else setGames([]);
      })
      .catch((err) => {
        console.log("err", err);
        setGames([]);
      });
  }

  const handleButtonClick = (button) => {
    setActiveButton(button);
  };

  return (
    <div id="2" className="MainContainer">
      <div className="games-container">
        <div className="text-container">
          <div className="left-text">
            <h2>Our Platform</h2>
          </div>
          <div className="right-text">
            <button
              className={activeButton === "play" ? "active-button" : "inactive-button"}
              onClick={() => {
                setIsAgentActive(false);
                handleButtonClick("play");
              }}
            >
              Player
            </button>
            <button
              className={activeButton === "agent" ? "active-button" : "inactive-button"}
              onClick={() => {
                setIsAgentActive(true);
                handleButtonClick("agent");
              }}
            >
              Agent
            </button>
          </div>
        </div>
      </div>
      {isAgentActive ? <AgentCards games={games} /> : <PlayersCards games={games} />}
    </div>
  );
};

export default Games;
