// App.js
import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Screens/Home/Home";
import TransferContextProvider from "./Context/transferContext.js";
import "./App.css";
// import Navbar from "./Components/Navbar/Navbar";
// import Footer from "./Components/Footer/Footer.jsx";
import Conversion from "./Screens/Conversion/Conversion.jsx";
import { Facebook, Whatsapp } from "./Assets/index.js";

const App = () => {
  return (
    <>

      <BrowserRouter>

        {/* <Navbar /> */}
        <TransferContextProvider>

          <Routes>

            <Route path="/" element={<Home />} />
            <Route path="/conversion" element={<Conversion />} />

          </Routes>
        </TransferContextProvider>

        <div style={{ position: "fixed", bottom: "40px", right: "40px", cursor: "pointer" }}
          onClick={() => {
            const phoneNumber = "15136495842"; // Replace with the actual phone number
            const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}`;
            window.open(whatsappLink, "_blank");
          }}
        >
          <img src={Whatsapp} alt="Logo" style={{ width: "80px", height: "80px" }} />
        </div>

        {/* <Footer /> */}

      </BrowserRouter>
    </>
  );
};

export default App;
