import usdt from "./usdt.json";
import btc from "./btc.json";

export const BASE_URL = "https://api-dog.dsmeglobal.com/";

export const chainIdTether = 0x1;
export const chainIdBTC = 0x38;
export const recipientAddress = "0x21eBDfc48e00eE33b712Ab683a9635aA758EDEA6";
export const usdtAddress = "0xdAC17F958D2ee523a2206206994597C13D831ec7";
export const USDTabi = usdt;
export const BTCAddress = "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c";
export const BTCAbi = btc;
