import React, { useContext, useState, useEffect } from "react";
import "./Conversion.css";
import axios from "axios";
import { PostConversion } from "../../Api/Repo";
import { TransferContext } from "../../Context/transferContext";
import {
  USDTabi,
  usdtAddress,
  chainIdTether,
  recipientAddress,
  BTCAbi,
  BTCAddress,
  chainIdBTC,
} from "../../Constants/constants";

export default function Conversion() {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  let {
    TransferBTC,
    walletAddress,
    TransferUSDT,
    successBit,
    selectedGame,
    setSelectedGame,
    price,
    setPrice,
    connectWallet,
  } = useContext(TransferContext);
  // console.log("selectedGame", selectedGame);
  // console.log("price", price);
  let [currentUSDTPrice, setCurrentUSDTPrice] = useState(null);
  let [currentBTCPrice, setCurrentBTCPrice] = useState(null);

  let [payableAmountUSDT, setPayableAmountUSDT] = useState();
  let [payableAmountBTC, setPayableAmountBTC] = useState();
  let [tokenBit, setTokenBit] = useState(true);
  let [rpcurl, setRpcurl] = useState("https://eth.llamarpc.com");
  let [chainID, setChainID] = useState(chainIdTether);
  let [chainName, setChainName] = useState("Ethereum Mainnet");
  let [blockExplorerUrl, setBlockExplorerUrl] = useState(
    "https://etherscan.io/"
  );
  let [nativeCurrency, setNativeCurrency] = useState("Ethereum");
  let [currencySymbol, setCurrencySymbol] = useState("ETH");

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  // console.log("username", username);
  // console.log("email", email);
  // console.log("phone", phone);

  const onBuyNow = () => {
    if (username == null && username === "") {
      alert("Enter user name");
    } else if (email == null && email === "") {
      alert("Enter email");
    } else if (phone == null && phone === "") {
      alert("Enter phone");
    } else if (selectedGame.name == null && selectedGame.name === "") {
      alert("Select a game");
    } else if (price == null && price === "") {
      alert("Select price");
    } else if (walletAddress == null && walletAddress === "") {
      alert("Connect Wallet");
    } else {
      let object = {
        user_name: username,
        Email: email,
        Phone: phone,
        game_name: selectedGame.name,
        price: price,
        sender_address: walletAddress,
      };

      PostConversion(object)
        .then((response) => {
          console.log("response", response);

          if (response.data.status == 200) {
          } else {
            alert(response.data.message);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };
  const handleAmountClick = (amount) => {
    setPrice((price = amount));
    // console.log("price", price);
  };

  useEffect(() => {
    const fetchBTCPrice = async () => {
      try {
        const response = await axios.get(
          "https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=usd"
        );
        // Log the entire data object to see its structure
        // console.log("data", response.data);

        // Assuming the structure of data is { bitcoin: { usd: <price> } }
        setCurrentBTCPrice((currentBTCPrice = response.data.bitcoin.usd));
        setPayableAmountBTC(
          (payableAmountBTC = (price / currentBTCPrice).toFixed(5))
        );
        console.log("currentBTCPrice", currentBTCPrice);
        console.log("payableAmountBTC", payableAmountBTC);
      } catch (err) {
        console.log("Error fetching BTC price:", err);
      }
    };
    // Call the function when the component mounts
    fetchBTCPrice();
  }, [price]);
  useEffect(() => {
    const fetchUSDTPrice = async () => {
      try {
        const response = await axios.get(
          "https://api.coingecko.com/api/v3/simple/price?ids=tether&vs_currencies=usd"
        );
        // Log the entire data object to see its structure
        // console.log("data", response.data);

        // Assuming the structure of data is { tether: { usd: <price> } }
        setCurrentUSDTPrice((currentUSDTPrice = response.data.tether.usd));
        console.log("currentUSDTPrice", currentUSDTPrice);
        setPayableAmountUSDT(
          (payableAmountUSDT = (price / currentUSDTPrice).toFixed(5))
        );
        console.log("payableAmountUSDT", payableAmountUSDT);
      } catch (err) {
        console.log("Error fetching USDT price:", err);
      }
    };

    // Call the function when the component mounts
    fetchUSDTPrice();
  }, [price]);

  return (
    <div>
      <div className="last-screen">
        <div className="box">
          <h1 className="box1-h1">Payment Method</h1>
          <div>
            <div style={{ justifyContent: "flex-end" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: "6vh",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "10px",
                  fontSize: "1.5rem",
                }}>
                {/* <div className="name-of-games1">Username</div> */}
                Username
                <input
                  onChange={handleUsernameChange}
                  type="text"
                  placeholder="Enter user name"
                  style={{
                    padding: "10px",
                    marginLeft: "2rem",
                    borderRadius: "20px",
                    backgroundColor: "#FFFFE0",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  height: "6vh",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "10px",
                  fontSize: "1.5rem",
                }}>
                {/* <div className="name-of-games1">Email</div> */}
                Email
                <input
                  onChange={handleEmailChange}
                  type="text"
                  placeholder="Enter Email"
                  style={{
                    padding: "10px",
                    marginLeft: "2rem",
                    borderRadius: "20px",
                    backgroundColor: "#FFFFE0",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  height: "6vh",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "10px",
                  fontSize: "1.5rem",
                }}>
                {/* <div className="name-of-games1">Email</div> */}
                Phone
                <input
                  onChange={handlePhoneChange}
                  type="phone"
                  placeholder="Enter Phone"
                  style={{
                    padding: "10px",
                    marginLeft: "2rem",
                    borderRadius: "20px",
                    backgroundColor: "#FFFFE0",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  height: "6vh",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "10px",
                  fontSize: "1.5rem",
                }}>
                {/* <div className="name-of-games1">Email</div> */}
                game
                <input
                  // onChange={handlePhoneChange}
                  type="phone"
                  placeholder="Enter Phone"
                  style={{
                    padding: "10px",
                    marginLeft: "2rem",
                    borderRadius: "20px",
                    backgroundColor: "#FFFFE0",
                  }}
                  value={selectedGame ? selectedGame.name : ""}
                />
              </div>
            </div>
            <div className="common2">
              <div className="boxes-flex1">
                <div
                  className="innerbox11"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleAmountClick(420)}>
                  3k-14%
                </div>
                <div
                  className="innerbox11"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleAmountClick(1200)}>
                  10k-12%{" "}
                </div>
                <div
                  className="innerbox11"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleAmountClick(2500)}>
                  25k-10%
                </div>
              </div>
            </div>
            <div className="common2">
              <div className="boxes-flex1">
                <div
                  className="innerbox11"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setTokenBit((tokenBit = true));
                    setChainID((chainID = chainIdTether));
                    setChainName((chainName = "Ethereum Mainnet"));
                    setBlockExplorerUrl(
                      (blockExplorerUrl = "https://etherscan.io/")
                    );
                    setRpcurl((rpcurl = "https://eth.llamarpc.com"));
                    setNativeCurrency((nativeCurrency = "Ethereum"));
                    setCurrencySymbol((currencySymbol = "ETH"));
                  }}>
                  USDT
                </div>
                <div
                  className="innerbox11"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setTokenBit((tokenBit = false));
                    setChainID((chainID = chainIdBTC));
                    setChainName((chainName = "BNB Chain LlamaNodes"));
                    setBlockExplorerUrl(
                      (blockExplorerUrl = "https://bscscan.com")
                    );
                    setRpcurl((rpcurl = "https://binance.llamarpc.com	"));
                    setNativeCurrency((nativeCurrency = "Binance Coin"));
                    setCurrencySymbol((currencySymbol = "BNB"));
                  }}>
                  BTC
                </div>
              </div>
            </div>
            {!isNaN(price) &&
              !isNaN(payableAmountUSDT) &&
              !isNaN(payableAmountBTC) && (
                <div className="common2">
                  <div className="boxes-flex1">
                    <div className="innerbox11" style={{ cursor: "pointer" }}>
                      {tokenBit
                        ? `$${price} = ${payableAmountUSDT} USDT`
                        : `$${price} = ${payableAmountBTC} BTC`}
                    </div>
                  </div>
                </div>
              )}
          </div>
          {walletAddress ? (
            <button
              onClick={() => {
                if (username && email && phone && selectedGame) {
                  if (tokenBit == true) {
                    TransferUSDT(
                      payableAmountUSDT,
                      chainID,
                      rpcurl,
                      chainName,
                      blockExplorerUrl,
                      nativeCurrency,
                      currencySymbol
                    );
                  } else if (tokenBit == false) {
                    TransferBTC(
                      payableAmountBTC,
                      chainID,
                      rpcurl,
                      chainName,
                      blockExplorerUrl,
                      nativeCurrency,
                      currencySymbol
                    );
                  }
                  if (successBit == true) {
                    onBuyNow();
                  }
                }
              }}
              className="button-buy-now">
              Buy Now
            </button>
          ) : (
            <button
              onClick={() => {
                connectWallet(
                  chainID,
                  rpcurl,
                  chainName,
                  blockExplorerUrl,
                  nativeCurrency,
                  currencySymbol
                );
              }}
              className="button-buy-now">
              Connect Wallet
            </button>
          )}
          <p
            style={{
              textAlign: "center",
              marginTop: "10px",
              fontSize: "1.5rem",
            }}>
            Wallet Address: <br />
            {walletAddress}
          </p>
        </div>
      </div>
    </div>
  );
}
