import React, { useState, useEffect } from "react";
import "./appNavbar.css";
// import { useNavigate } from "react-router-dom";
import { FiAlignRight, FiX } from "react-icons/fi";
import Fade from "react-reveal/Fade";
import { DogLogo } from "../../Assets";

function Navbar(props) {
  // const navigate = useNavigate();
  const [isScrolling, setIsScrolling] = useState(false);
  const [isActive, setIsActive] = useState(0);
  const [toggleMenu, setToggleMenu] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        setIsScrolling(true);
        setToggleMenu(false);
      } else if (window.scrollY === 0) {
        setIsScrolling(false);
      }
    });

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("scroll", () => {});
    };
  }, []); // Add an empty dependency array to useEffect to ensure it runs only once

  function scrolldiv(scroll) {
    var elem = document.getElementById(scroll);

    // var firstElement = elem[0];
    if (elem) {
      elem.scrollIntoView({ behavior: "smooth" });
    }
    // elem.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <Fade top>
      <nav
        className={
          isScrolling
            ? "NavBar_Main_Container Active_Header"
            : "NavBar_Main_Container"
        }>
        <div
          className="logoContainer"
          style={{ display: "flex", alignItems: "center" }}>
          <img src={DogLogo} className="Nav_Image_Style" alt="no images" />
        </div>

        <div>
          <div className={"Navlink_Wrapper"}>
            <a
              // href="/"
              onClick={() => {
                setIsActive(0);
                scrolldiv("1");
              }}
              className={isScrolling ? "Link_Style_Scroll" : "Link_Style"}>
              Home
            </a>
            <a
              // href="/"
              onClick={() => {
                setIsActive(1);
                // Additional logic specific to the "Game Links" link if needed
                // navigate("/game-links");
                scrolldiv("2");
              }}
              // style={{
              //   color: isActive === 1 ? "black" : "#FDFF01",
              // }}
              className={isScrolling ? "Link_Style_Scroll" : "Link_Style"}>
              Game Links
            </a>
            <a
              // href="/"
              onClick={() => {
                setIsActive(2);
                // Additional logic specific to the "Services" link if needed
                // navigate("/services");
                scrolldiv("3");
              }}
              className={isScrolling ? "Link_Style_Scroll" : "Link_Style"}>
              Services
            </a>
          </div>
        </div>

        <div className={"NavLink_App_Smaller_Devices"}>
          {toggleMenu ? (
            <div className="Toggle_Btn_CLose">
              <FiX
                color="#FDFF02"
                size={30}
                onClick={() => setToggleMenu(false)}
              />
            </div>
          ) : (
            <div className="Toggle_Btn_Open">
              <FiAlignRight
                color="#FDFF02"
                size={30}
                onClick={() => setToggleMenu(true)}
              />
            </div>
          )}

          {toggleMenu ? (
            <div className={"NavLink_App_Smaller_Overlay"}>
              <div className={"Navlink_App_Links_Wrapper_SmallScreen_links"}>
                <div
                  className={"Link_Style_Small_Device"}
                  onClick={() => {
                    setIsActive(0);
                    scrolldiv("1");
                  }}
                  style={{
                    backgroundColor: isActive === 0 ? "yellow" : null,
                    color: isActive === 0 ? "black" : "white",
                  }}>
                  Home
                </div>

                <div
                  className={"Link_Style_Small_Device"}
                  onClick={() => {
                    setIsActive(0);
                    scrolldiv("2");
                  }}
                  style={{
                    backgroundColor: isActive === 0 ? "yellow" : null,
                    color: isActive === 0 ? "black" : "white",
                  }}>
                  Game Links
                </div>

                <div
                  className={"Link_Style_Small_Device"}
                  onClick={() => {
                    setIsActive(0);
                    scrolldiv("3");
                  }}
                  style={{
                    backgroundColor: isActive === 0 ? "yellow" : null,
                    color: isActive === 0 ? "black" : "white",
                  }}>
                  Services
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </nav>
    </Fade>
  );
}

export default Navbar;
