import React from "react";
import "./services.css"; // Make sure to import or define your CSS styles
import { PaymentIcon, icon1, icon24 } from "../../../Assets";

const Services = () => {
  return (
    <div id="3" className="CustomBoxContainer">
      <h1 className="serviceHeading">Our services</h1>
      <div className="CustomBoxContent">
        <div className="innercontainerbox">
          <img src={icon1} alt="joker" className="icon1style" />
          <h1 className="exclusiveHeading">Exclusive Offer</h1>
          <p1 className="exclusivePara">"Unlock an unparalleled gaming experience with our exclusive casino games website. Enjoy premium rewards, thrilling gameplay, and unbeatable offers.</p1>
        </div>
        <div className="innercontainerbox">
          <img src={icon24} alt="joker" className="icon1style" />
          <h1 className="exclusiveHeading">24 / 7 Services</h1>
          <p1 className="exclusivePara">Experience the epitome of convenience casino services. Dive into non-stop excitement and enjoy round-the-clock access to your favorite games.</p1>
        </div>
        <div className="innercontainerbox">
          <img src={PaymentIcon} alt="joker" className="icon1style" />
          <h1 className="exclusiveHeading">Secure Payments</h1>
          <p1 className="exclusivePara">Rest easy with secure payment options. We prioritize your safety, ensuring that transaction is protected. Enjoy seamless deposits and withdrawals</p1>
        </div>
      </div>
    </div>
  );
};

export default Services;
