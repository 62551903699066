import React from "react";
import "./home.css"; // You can create and import home.css if needed.
// import Header from "../../Screens/Header/Banner/Banner"
// import Coins from "../../Screens/Header/Coins-Images/Coins"
import Games from "../../Screens/Header/Games/Games";
import Services from "../Header/Services/services";
import HomeVideo from "../Header/Video/Video";
import PricingPlan from "../Header/Pricing/Pricing";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import { Facebook } from "../../Assets";

const Home = () => {
  return (
    <>
      <Navbar />
      <HomeVideo />
      {/* <Header /> */}
      {/* <Coins /> */}
      <Games />
      <Services />
      <PricingPlan />
      <Footer />
    </>
  );
};

export default Home;
