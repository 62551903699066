import React, { useContext, useState, useEffect } from "react";
import "./Pricing.css"; // Make sure to provide the correct path to your CSS file
import { useNavigate } from "react-router-dom";
import { TransferContext } from "../../../Context/transferContext";
const PricingPlan = () => {
  const gamesData = [
    { name: "Cash Machine", prices: [420, 1200, 2500] },
    { name: "Mawal Gr", prices: [420, 1200, 2500] },
    { name: "Mr.AllinOne", prices: [420, 1200, 2500] },
    { name: "Vegas Luck", prices: [420, 1200, 2500] },
    { name: "Gameroom", prices: [420, 1200, 2500] },
    { name: "King of Pop", prices: [420, 1200, 2500] },
    { name: "Noble", prices: [420, 1200, 2500] },
    { name: "Mafia", prices: [420, 1200, 2500] },
    { name: "WinStar", prices: [420, 1200, 2500] },
  ];

  let { selectedGame, setSelectedGame, price, setPrice } =
    useContext(TransferContext);
  const navigate = useNavigate();
  return (
    <>
      <div className="last-screen">
        <div className="box">
          <h1 className="box1-h1">Diamond Dragon Gaming</h1>
          <div className="flex">
            <div className="common1">
              {/* Use map to dynamically generate game names */}
              {gamesData.map((game, index) => (
                <>
                  {/* <div style={{backgroundColor:'white',borderColor:'white',borderWidth:'1px',height:'1px',width:'100%'}}/> */}
                  <div
                    style={{ cursor: "pointer" }}
                    key={index}
                    className={
                      index === 0 ? "name-of-games1" : "name-of-games2"
                    }
                    onClick={() => setSelectedGame(game)}>
                    {game.name}
                    {/* { console.log("selectedGame",selectedGame)} */}
                  </div>
                </>
              ))}
            </div>

            <div className="common2">
              <div
                style={{
                  backgroundColor: "#FDFF02",
                  borderWidth: "1px",
                  height: "1px",
                  width: "100%",
                }}
              />
              <div className="boxes-flex1">
                <div
                  style={{
                    backgroundColor: "#FDFF02",
                    borderWidth: "1px",
                    height: "100%",
                    width: "1px",
                  }}
                />
                <div className="innerbox11" style={{ cursor: "pointer" }}>
                  3k-14%
                </div>
                <div
                  style={{
                    backgroundColor: "#FDFF02",
                    borderWidth: "1px",
                    height: "100%",
                    width: "1px",
                  }}
                />
                <div className="innerbox11" style={{ cursor: "pointer" }}>
                  10k-12%
                </div>
                <div
                  style={{
                    backgroundColor: "#FDFF02",
                    borderWidth: "1px",
                    height: "100%",
                    width: "1px",
                  }}
                />
                <div className="innerbox11" style={{ cursor: "pointer" }}>
                  25k-10%
                </div>
                <div
                  style={{
                    backgroundColor: "#FDFF02",
                    borderWidth: "1px",
                    height: "100%",
                    width: "1px",
                  }}
                />
              </div>

              {gamesData.map((game, index) => (
                <>
                  <div
                    style={{
                      backgroundColor: "white",
                      borderWidth: "0.5px",
                      height: "1px",
                      width: "100%",
                    }}
                  />
                  <div className="boxes-flex">
                    <div
                      style={{
                        backgroundColor: "#FDFF02",
                        borderWidth: "1px",
                        height: "100%",
                        width: "1px",
                      }}
                    />
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSelectedGame(game);
                        setPrice(420);
                        navigate("/conversion");
                      }}
                      className="innerbox1">
                      $420
                    </div>
                    <div
                      style={{
                        backgroundColor: "#FDFF02",
                        marginLeft: "-3px",
                        borderWidth: "1px",
                        height: "100%",
                        width: "1px",
                      }}
                    />
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSelectedGame(game);
                        setPrice(1200);
                        navigate("/conversion");
                      }}
                      className="innerbox1">
                      $1.2k
                    </div>
                    <div
                      style={{
                        backgroundColor: "#FDFF02",
                        borderWidth: "1px",
                        height: "100%",
                        width: "1px",
                      }}
                    />
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSelectedGame(game);
                        setPrice(2500);
                        navigate("/conversion");
                      }}
                      className="innerbox1">
                      $2.5k
                    </div>
                    <div
                      style={{
                        backgroundColor: "#FDFF02",
                        borderWidth: "0.5px",
                        height: "100%",
                        width: "1px",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      backgroundColor: "#FDFF02",
                      borderWidth: "0.5px",
                      height: "1px",
                      width: "100%",
                    }}
                  />
                </>
              ))}

              {/* <div style={{backgroundColor:'white',borderColor:'white',borderWidth:'1px',height:'1px',width:'100%'}}/>
              <div className="boxes-flex">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSelectedGame(selectedGame=gamesData.find((game) => game.name === "Mawal Gr"));
                    setPrice(price=420)
                    navigate("/conversion");
                  }}
                  className="innerbox1">
                  $420
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSelectedGame(selectedGame=gamesData.find((game) => game.name === "Mawal Gr"));
                    setPrice(price=1200)
                    navigate("/conversion");
                  }}
                  className="innerbox1">
                  $1.2k
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSelectedGame(selectedGame=gamesData.find((game) => game.name === "Mawal Gr"));
                    setPrice(price=2500)
                    navigate("/conversion");
                  }}
                  className="innerbox1">
                  $2.5k
                </div>
              </div>
              <div style={{backgroundColor:'white',borderColor:'white',borderWidth:'1px',height:'1px',width:'100%'}}/>
              <div className="boxes-flex">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSelectedGame(selectedGame=gamesData.find((game) => game.name === "Mr.AllinOne"));
                    setPrice(price=420)
                    navigate("/conversion");
                  }}
                  className="innerbox1">
                  $420
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSelectedGame(selectedGame=gamesData.find((game) => game.name === "Mr.AllinOne"));
                    setPrice(price=1200)
                    navigate("/conversion");
                  }}
                  className="innerbox1">
                  $1.2k
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSelectedGame(selectedGame=gamesData.find((game) => game.name === "Mr.AllinOne"));
                    setPrice(price=2500)
                    navigate("/conversion");
                  }}
                  className="innerbox1">
                  $2.5k
                </div>
              </div>
              <div style={{backgroundColor:'white',borderColor:'white',borderWidth:'1px',height:'1px',width:'100%'}}/>
              <div className="boxes-flex">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSelectedGame(selectedGame=gamesData.find((game) => game.name === "Vegas Luck"));
                    setPrice(price=420)
                    navigate("/conversion");
                  }}
                  className="innerbox1">
                  $420
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSelectedGame(selectedGame=gamesData.find((game) => game.name === "Vegas Luck"));
                    setPrice(price=1200)
                    navigate("/conversion");
                  }}
                  className="innerbox1">
                  $1.2k
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSelectedGame(selectedGame=gamesData.find((game) => game.name === "Vegas Luck"));
                    setPrice(price=2500)
                    navigate("/conversion");
                  }}
                  className="innerbox1">
                  $2.5k
                </div>
              </div>
              <div style={{backgroundColor:'white',borderColor:'white',borderWidth:'1px',height:'1px',width:'100%'}}/>
              <div className="boxes-flex">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSelectedGame(selectedGame=gamesData.find((game) => game.name === "Gameroom"));
                    setPrice(price=420)
                    navigate("/conversion");
                  }}
                  className="innerbox1">
                  $420
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSelectedGame(selectedGame=gamesData.find((game) => game.name === "Gameroom"));
                    setPrice(price=1200)
                    navigate("/conversion");
                  }}
                  className="innerbox1">
                  $1.2k
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSelectedGame(selectedGame=gamesData.find((game) => game.name === "Gameroom"));
                    setPrice(price=2500)
                    navigate("/conversion");
                  }}
                  className="innerbox1">
                  $2.5k
                </div>
              </div>
              <div style={{backgroundColor:'white',borderColor:'white',borderWidth:'1px',height:'1px',width:'100%'}}/>
              <div className="boxes-flex">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSelectedGame(selectedGame=gamesData.find((game) => game.name === "King of Pop"));
                    setPrice(price=420)
                    navigate("/conversion");
                  }}
                  className="innerbox1">
                  $420
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSelectedGame(selectedGame=gamesData.find((game) => game.name === "King of Pop"));
                    setPrice(price=1200)
                    navigate("/conversion");
                  }}
                  className="innerbox1">
                  $1.2k
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSelectedGame(selectedGame=gamesData.find((game) => game.name === "King of Pop"));
                    setPrice(price=2500)
                    navigate("/conversion");
                  }}
                  className="innerbox1">
                  $2.5k
                </div>
              </div>
              <div style={{backgroundColor:'white',borderColor:'white',borderWidth:'1px',height:'1px',width:'100%'}}/>
              <div className="boxes-flex">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSelectedGame(selectedGame=gamesData.find((game) => game.name === "Noble"));
                    setPrice(price=420)
                    navigate("/conversion");
                  }}
                  className="innerbox1">
                  $420
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSelectedGame(selectedGame=gamesData.find((game) => game.name === "Noble"));
                    setPrice(price=1200)
                    navigate("/conversion");
                  }}
                  className="innerbox1">
                  $1.2k
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSelectedGame(selectedGame=gamesData.find((game) => game.name === "Noble"));
                    setPrice(price=2500)
                    navigate("/conversion");
                  }}
                  className="innerbox1">
                  $2.5k
                </div>
              </div>
              <div style={{backgroundColor:'white',borderColor:'white',borderWidth:'1px',height:'1px',width:'100%'}}/>
              <div className="boxes-flex">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSelectedGame(selectedGame=gamesData.find((game) => game.name === "Mafia"));
                    setPrice(price=420)
                    navigate("/conversion");
                  }}
                  className="innerbox1">
                  $420
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSelectedGame(selectedGame=gamesData.find((game) => game.name === "Mafia"));
                    setPrice(price=1200)
                    navigate("/conversion");
                  }}
                  className="innerbox1">
                  $1.2k
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSelectedGame(selectedGame=gamesData.find((game) => game.name === "Mafia"));
                    setPrice(price=2500)
                    navigate("/conversion");
                  }}
                  className="innerbox1">
                  $2.5k
                </div>
              </div>
              <div style={{backgroundColor:'white',borderColor:'white',borderWidth:'1px',height:'1px',width:'100%'}}/>
              <div className="boxes-flex">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSelectedGame(selectedGame=gamesData.find((game) => game.name === "WinStar"));
                    setPrice(price=420)
                    navigate("/conversion");
                  }}
                  className="innerbox1">
                  $420
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSelectedGame(selectedGame=gamesData.find((game) => game.name === "WinStar"));
                    setPrice(price=1200)
                    navigate("/conversion");
                  }}
                  className="innerbox1">
                  $1.2k
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSelectedGame(selectedGame=gamesData.find((game) => game.name === "WinStar"));
                    setPrice(price=2500)
                    navigate("/conversion");
                  }}
                  className="innerbox1">
                  $2.5k
                </div>
              </div>
              <div style={{backgroundColor:'white',borderColor:'white',borderWidth:'1px',height:'1px',width:'100%'}}/> */}
              {/* Repeat the structure for other sections as needed */}
            </div>
          </div>
          <button
            className="button-buy-now"
            onClick={() => {
              navigate("/conversion");
            }}>
            Buy Now
          </button>
        </div>
      </div>
    </>
  );
};

export default PricingPlan;
